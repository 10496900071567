import { createLink } from "../../../api/api";
import { useState } from "react";
import { useAppContext } from "../../../contexts/AppContext";
import {toast} from "react-toastify";
import { getToken } from "../../../utils";

const LinkAdd = (props) => {
  const [formData, setFormData] = useState({
    title: "",
    link: "",
    description: "",
  });
  const { appState } = useAppContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      const token = getToken();
    const username = appState.email.split("@")[0];

    const response = await createLink({
      username,
      title: formData.title,
      link: formData.link,
      description: formData.description,
      token,
    });

    toast.success("Link created successfully");
    props.setLinks([
      ...props.links,
      { ...response, id: props.links.length + 1 },
    ]);
    props.setOpen(false);
  };

  return (
    <div className="add">
      <div className="modal">
        <span className="close" onClick={() => props.setOpen(false)}>
          X
        </span>
        <h1>Add new {props.slug}</h1>
        <form onSubmit={handleSubmit}>
          <div className="item" key="title">
            <label>Title</label>
            <input
              type="text"
              name="title"
              placeholder="Title"
              style={{ width: "100%" }}
              value={formData.title || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="item" key="link">
            <label>Link</label>
            <input
              type="text"
              name="link"
              placeholder="Link"
              style={{ width: "100%" }}
              value={formData.link || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="item" key="description">
            <label>Description</label>
            <input
              type="text"
              name="description"
              placeholder="Desscription"
              style={{ width: "100%" }}
              value={formData.description || ""}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default LinkAdd;