import {
  ChevronFirst,
  ChevronLast,
  LogOut,
  Moon,
  Sun,
} from "lucide-react";
import React, { createContext, useState } from "react";
import { useAppContext } from "../contexts/AppContext";
import HorizontalModal from "./DevicesModal.js";
import FeedbackModal from "./FeedbackModal.js";
import Modal from "./Modal";
import { useSideBarContext } from "../contexts/SideBarContext.js";
import { sideNav } from "../data.js";
import SideNav from "./SideNav.js";
import { removeToken } from "../utils.js";
export const SidebarContext = createContext();

const getInitials = (displayName) => {
  const nameParts = displayName.split(" ");
  const initials = nameParts
    .slice(0, 2)
    .map((part) => part.charAt(0))
    .join("")
    .toUpperCase();
  return initials;
};

const HelpCard = ({ darkMode, expanded }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <li
      className={`relative z-0 w-full p-4 text-left mb-4 ${
        expanded ? "block" : "hidden"
      }`}
    >
      <div
        className={`flex flex-col justify-between p-4 rounded-lg shadow-soft-2xl font-futura bold`}
        style={{
          background: darkMode
            ? "linear-gradient(to bottom right,#52b44b, #BBF7D0)"
            : "linear-gradient(to bottom right, #BBF7D0,#52b44b)",
          color: darkMode ? "white" : "#2D3748",
        }}
      >
        <div>
          <div className="transition-all duration-200 ease-nav-brand">
            <h6 className="mb-2">Got an idea?</h6>
            <p className="mb-4 text-xs">Let us know how to improve</p>
          </div>
        </div>
        <button
          className="inline-block w-full px-8 py-2 mb-0 bold text-center text-black uppercase transition-all ease-in border-0 rounded-lg shadow-soft-md bg-white leading-pro text-xs hover:shadow-soft-2xl hover:scale-102"
          onClick={handleOpenModal}
        >
          {darkMode ? "Submit Feedback" : "Submit Feedback"}
        </button>
      </div>
      <FeedbackModal open={openModal} onClose={handleCloseModal} />
    </li>
  );
};

export default function Sidebar({ children }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [showDevices, setShowDevices] = useState(false);
  const { appUser, loading } = useAppContext();
  const { expanded, darkMode, toggleExpanded, toggleDarkMode } =
    useSideBarContext();


  const closeModal = () => {
    setModalOpen(false);
  };

  const handleDeviceStatus = () => {
    setShowDevices(!showDevices);
  };

  return (
    <aside className={`h-screen font-futura bold ${darkMode ? "dark" : ""}`}>
      <nav
        className={`h-full flex flex-col ${
          darkMode ? "bg-gray-800 text-white" : "bg-white"
        } border-r shadow-sm ${expanded ? "w-72" : "w-20"} transition-all`}
      >
        <div className="p-4 pb-2 flex justify-between items-center">
          <img
            src={"/images/Safaricom-Logo.wine.svg"}
            className={`overflow-hidden transition-all ${
              expanded ? "w-40" : "w-0"
            }`}
            alt="LOGO"
          />
          <button onClick={toggleExpanded}>
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>

        {/* Provide expanded and darkMode states, and toggleDarkMode function in SidebarContext */}
        <SidebarContext.Provider>
          <ul className="flex-1 px-3 mt-4">
            {children}
            {/* <SidebarItem icon={<BotIcon size={18} />} text="Chatbot" /> */}
            <SideNav menu={sideNav} />
            {/* <SidebarItem icon={<MessagesSquareIcon size={18} />} text="Forum" /> */}
          </ul>
          {/* <AccountInfo darkMode={darkMode} expanded={expanded} /> */}
          {/* {appState.email.toLowerCase() ===
            ("AKkiprono@safaricom.co.ke".toLowerCase() ||
              "tkipngeno@safaricom.co.ke".toLowerCase()) && (
            <SidebarItem
              onClick={handleFormUpload}
              icon={<UploadCloudIcon size={18} />}
              text={
                <p className="font-futura-bold text-lg bold">Upload FAQs</p>
              }
            />
          )} */}

          <HelpCard darkMode={darkMode} expanded={expanded} />

          {/* <SidebarItem
            onClick={handleFormUpload}
            icon={<UploadCloudIcon size={18} />}
            text={<p className="font-futura-bold text-lg bold">Upload FAQs</p>}
          /> */}
          <SidebarItem
            icon={darkMode ? <Sun size={18} /> : <Moon size={18} />}
            text={
              darkMode ? (
                <p className="font-futura-bold text-lg bold">Light Mode</p>
              ) : (
                <p className="font-futura-bold text-lg bold">Dark Mode</p>
              )
            }
            onClick={toggleDarkMode}
          />
          <SidebarItem
            onClick={() => {
              if (!loading && appUser) {
                removeToken();
                window.location.reload();
              }
            }}
            icon={<LogOut size={18} />}
            text={<p className="font-futura-bold text-lg bold">LogOut</p>}
          />
        </SidebarContext.Provider>

        <div className="border-t flex p-3 mt-auto">
          <div
            className={`w-10 h-10 rounded-md flex justify-center items-center`}
            style={{ backgroundColor: darkMode ? "gray" : "#52b44b" }}
          >
            {loading ? (
              <div></div>
            ) : !appUser ? (
              "No data"
            ) : (
              getInitials(appUser.displayName)
            )}
          </div>
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
              `}
          >
            <div className="leading-4">
              <h4
                className={`font-semibold ${
                  darkMode ? "text-white" : "text-black"
                }`}
              >
                {loading && !appUser ? (
                  <div></div>
                ) : !appUser ? (
                  "No data"
                ) : (
                  `${appUser.displayName.split(" ")[0]} ${appUser.displayName
                    .split(" ")
                    .pop()}`
                )}
              </h4>
              <span
                className={`text-xs ${
                  darkMode ? "text-gray-300" : "text-gray-600"
                }`}
              >
                {loading ? <div></div> : !appUser ? "No data" : `${appUser.email}`}
              </span>
            </div>
            {/* <MoreVertical size={20} /> */}
          </div>
        </div>
      </nav>
      {modalOpen && <Modal closeModal={closeModal} />}
      {showDevices && <HorizontalModal onClose={handleDeviceStatus} />}
    </aside>
  );
}

export function SidebarItem({ icon, text, active, onClick }) {
  const { expanded } = useSideBarContext();

  return (
    <li
      className={`
        relative flex items-center py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${
          active
            ? "bg-gradient-to-tr from-green-100 to-green-100 text-green-800"
            : "hover:bg-green-500 text-black-100"
        }
    `}
      onClick={onClick}
    >
      {icon}
      <span
        className={`overflow-hidden transition-all ${
          expanded ? "w-52 ml-3" : "w-0"
        } ${expanded ? "" : "hidden"}`}
      >
        {text}
      </span>
    </li>
  );
}
