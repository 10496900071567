import axios from "axios";

// const authorizationToken = process.env.REACT_APP_AUTH_TOKEN;

// Base URLs
const BASE_URL = "https://apistg.safaricom.co.ke/arifa/v1";

// Generate Access Token

async function fetchAccessToken() {
  const url =
    "https://apistg.safaricom.co.ke/oauth/cors/v1/generate?grant_type=client_credentials";

  const headers = {
    "Authorization":
      "Basic d0gzUmVBTTJZdUd5czI4NVJnd0dwQlFzc3h2M3NwTFVzRDhjMEFBS0tpSzdBZG9oOk05bUlPWDUyNEV4OXZ3Z3lFamZxR0pVV29QSzdUV1ZGZ0JHZXNEakdlTjBEVmRBMlVYUDBJWkEwb05wZmZFaFU=",
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data.access_token;
  } catch (error) {
    // Log any errors that occur
    console.error("Error:", error);
  }
}

//Get analytics summary

export async function getUserAnalyticsSummary(token) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/user-analytics/summary`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`Error: ${response.statusText}`);
    }
    
    return response.data.body;
  } catch (error) {
    console.error("Request failed to fetch summary", error);
  }
}

//Get user role

export async function getRole(username, token) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/user-analytics/users/role?username=${username}`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error updating role:",
      error.response ? error.response.data : error.message
    );
    return 400;
  }
}

//Update/Reset password

export async function resetPassword(user) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/reset-user-password`,
      {
        Username: user.username,
        Password: user.password,
      },
      {
        headers: {
          Authorization: "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.data.body;
  } catch (error) {
    console.error(
      "Error resetting password:",
      error.response ? error.response.data : error.message
    );
  }
}

//Update user role

export async function updateRole(username, role, token) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/user-analytics/users/role?username=${username}&role=${role}`,
      {
        body: null
      },
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response.data.body;
  } catch (error) {
    console.error(
      "Error updating role:",
      error.response ? error.response.data : error.message
    );
    return 400;
  }
}

//Unlock account

export async function unlockUserAccount(username, token) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/unlock-user?username=${username}`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    return response && response.data.header.responseCode
      ? response.data.header.responseCode
      : 400;
  } catch (error) {
    console.error(
      "Error unlocking account:",
      error.response ? error.response.data : error.message
    );
    return 400;
  }
}

//Get AD user information

export const getAdCredentials = async (username, token) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/user-info?username=${username}`,
      {
        headers: {
          Authorization: "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting user information");
    }

    return response.data;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Get total users

export async function getUsers(token) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/user-analytics/users`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response.data.body;
  } catch (error) {
    console.error("Request failed to fetch users", error);
  }
}

export const createCategory = async (username, category, token) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/categories?username=${username}`,
      {
        category_name: category,
      },
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 201) {
      throw new Error("Problem occurred creating a category");
    }

    return response.data.body;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

export const createLink = async (link) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/links?username=${link.username}`,
      {
        title: link.title,
        link: link.link,
        description: link.description,
      },
      {
        headers: {
          Authorization: "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + link.token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 201) {
      throw new Error("Problem occurred creating a link");
    }

    return response.data.body;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

export const getLinks = async (token) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(`${BASE_URL}/links`, {
      headers: {
        Authorization: "Bearer " + authToken,
        "X-AUTH-TOKEN": "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting links");
    }

    return response.data.body;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

export const getCategories = async (token) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/categories`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred while getting categories");
    }

    return response.data.body;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

//Create User

export const createUser = async (username, platform, token) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/user-analytics/user`,
      {
        username: username,
        platform: platform,
      },
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 201) {
      throw new Error("Problem occurred while creating a user");
    }
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

// Get user details

export const getUserDetails = async (username) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/ActiveDirectory/userdetails?username=${username}`,
      {
        body: null,
      },
      {
        headers: {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem occurred. Retry");
    }

    return response.data.body;
  } catch (error) {
    console.error("Error getting username :: ", error);
    throw error;
  }
};

// Generate and send OTP

export async function generateAndSendOTP(phoneNumber) {
  try {
    const token = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/sendOTP?phoneNumber=${phoneNumber}`,
      {
        body: null,
      },
      {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem while generating and sending OTP");
    }

    return response.data;
  } catch (error) {
    console.error("Error generating OTP :: ", error);
    throw error;
  }
}

// Validate OTP

export async function validateOTP(phoneNumber, otp, username) {
  try {
    const token = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/verifyOTP?phoneNumber=${phoneNumber}&enteredOTP=${otp}&username=${username}`,
      { body: null },
      {
        headers: {
          "Authorization": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem validating OTP");
    }

    return response.data.body.token;
  } catch (error) {
    console.error("Error validating OTP :: ", error);
    throw error;
  }
}

//Get Faqs

export async function getFaqs(token) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/faqs`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem getting faqs");
    }

    return response.data.body;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
}

//model endpoint

export async function getModel(topic, token) {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/faqs/faq?topic=${topic}`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Problem getting internal data");
    }

    return response.data.body.answer;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
}

// // Fetch FAQ request

// export const getFaq = async (faq) => {
//     try {
//       const response = await axios.post(
//         `${BASE_URL}/euda-api/v1/chatbot/query`, {
//           query: faq
//         }
//       );
//       if (response.status !== 200) {
//         throw new Error(`Failed to fetch FAQ`);
//       }

//       return response.data;

//     } catch (error) {
//       throw error;
//     }
// };

// Fetch all device details

export const getAllDevices = async (username, token) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/device-allocation?username=${username}`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`Failed to fetch devices.`);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching devices :: ", error);
    throw error;
  }
};

export const getAllFeedback = async (token) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.get(
      `${BASE_URL}/feedbacks`,
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`Failed to fetch feedback.`);
    }

    return response.data.body;
  } catch (error) {
    console.error("Error fetching feedback :: ", error);
    throw error;
  }
};

export const postFeedback = async (feedback) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/feedbacks`,
      {
        text: feedback.text,
        fileUrl: feedback.file,
      },
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + feedback.accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 201) {
      throw new Error(`Failed to post feedback.`);
    }

    return response.data;
  } catch (error) {
    console.error("Error posting feedback :: ", error);
    throw error;
  }
};

export const postFaq = async (faq) => {
  try {
    const authToken = await fetchAccessToken();
    const response = await axios.post(
      `${BASE_URL}/faqs?username=${faq.adUsername}`,
      {
        topic: faq.title,
        attachment: faq.category,
        answer: faq.description,
      },
      {
        headers: {
          "Authorization": "Bearer " + authToken,
          "X-AUTH-TOKEN": "Bearer " + faq.accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 201) {
      throw new Error(`Failed to post faq.`);
    }

    return response.data;
  } catch (error) {
    console.error("Error posting faq :: ", error);
    throw error;
  }
};