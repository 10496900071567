import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../../components/dataTable/DataTable";
import "./users.scss";
import { useEffect, useState } from "react";
import Add from "../../components/add/CategoryAdd";
import { userRows } from "../../../data";
import { getUsers, updateRole } from "../../../api/api";
import { getToken } from "../../../utils";
// import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "img",
    headerName: "Avatar",
    width: 100,
    renderCell: (params) => {
      return <img src={params.row.img || "/noavatar.png"} alt="" />;
    },
  },
  {
    field: "username",
    type: "string",
    headerName: "Username",
    width: 150,
  },
  {
    field: "email",
    type: "string",
    headerName: "Email",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    type: "string",
  },
  {
    field: "updatedAt",
    headerName: "Last Login",
    width: 200,
    type: "string",
  },
];

const Users = () => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const accessToken = getToken();
    const response = await getUsers(accessToken);
    setUsers(
      response.map((user, index) => ({
        ...user,
        id: index + 1,
        email: user.username + "@safaricom.co.ke",
        updatedAt: user.lastLogin,
      }))
    );
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleRole = async (username, role) => {
    const accessToken = getToken();
    const updatedRole =
      role === "USER"
        ? "ADMIN"
        : role === "SUPER_ADMIN"
        ? "SUPER_ADMIN"
        : role === "ADMIN"
        ? "SUPER_ADMIN"
            : "USER";
    const response = await updateRole(username, updatedRole, accessToken);
    setUsers(
      users.map((user) =>
        response.username.toLowerCase() === user.username.toLowerCase()
          ? { ...user, role: response.role }
          : user
      )
    );
  };

  const actionColumn = {
    field: "role",
    headerName: "Role",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="action">
          <button
            className={`${
              params.row.role === "SUPER_ADMIN"
                ? "bg-red-500"
                : params.row.role === "ADMIN"
                ? "bg-red-300"
                : "bg-green-500"
            } text-white font-bold py-2 px-4 rounded`}
            onClick={() => handleRole(params.row.username, params.row.role)}
          >
            {params.row.role}
          </button>
        </div>
      );
    },
  };

  return (
    <div className="users">
      <div className="info">
        <h1>Users</h1>
        {/* <button onClick={() => setOpen(true)}>Add New User</button> */}
      </div>
      <DataTable
        slug="users"
        columns={[...columns, actionColumn]}
        rows={users}
      />
      {/* TEST THE API */}

      {/* {isLoading ? (
        "Loading..."
      ) : (
        <DataTable slug="users" columns={columns} rows={data} />
      )} */}
      {open && <Add slug="user" columns={columns} setOpen={setOpen} />}
    </div>
  );
};

export default Users;
