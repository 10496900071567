import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { generateAndSendOTP, getUserDetails, validateOTP } from "../api/api";
import { toast } from "react-toastify";
import { Spinner } from "./Spinner";
import { useAppContext } from "../contexts/AppContext";
import styled from "styled-components";
import backgroundImage from "./bot.svg";
import logoImage from "./Safaricom-Logo.wine.svg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #52b44b 50%, white 50%);
  padding: 20px;
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 800px;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  background: url(${backgroundImage}) no-repeat center;
  background-size: contain;
  height: 200px; /* Adjust for mobile view */
  @media (min-width: 768px) {
    height: auto;
  }
`;

const FormSection = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: 50px;
  margin-right: 10px;
  @media (min-width: 768px) {
    width: 200px;
  }
`;

const AppName = styled.h1`
  font-size: 24px;
  background: linear-gradient(to right, #667eea, #764ba2);
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin: 0;
  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

const Description = styled.p`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #6b6b6b;
  font-size: 14px;
  @media (min-width: 768px) {
    text-align: left;
  }
`;

const AppDescription = styled.p`
  text-align: center;
  color: #6b6b6b;
  font-size: 16px;
  font-weight: 600; /* Semi-bold */
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
`;

const OTPInput = styled.input`
  width: 40px;
  padding: 15px;
  margin: 10px 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #52b44b, #764ba2);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
`;

const Link = styled.a`
  text-align: right;
  display: block;
  color: #667eea;
  margin-top: 10px;
  cursor: pointer;
`;

const OtpForm = () => {
  const {
    state,
    dispatch,
    username,
    setUsernameValue,
    setUsernameErrMsg
  } = useAuthContext();

  const { login } = useAppContext();

  // const [platform, setPlatform] = useState("");

      const [otpRequested, setOtpRequested] = useState(false);
      const [otp, setOtp] = useState(Array(6).fill(""));
      const otpRefs = useRef([]);

      useEffect(() => {
        if (otpRequested) {
          otpRefs.current[0]?.focus();
        }
      }, [otpRequested]);

  // useEffect(() => {
  //   const detectPlatform = () => {
  //     const userAgent = navigator.userAgent.toLowerCase();
  //     if (userAgent.includes("windows")) {
  //       return "Windows";
  //     } else if (
  //       userAgent.includes("macintosh") ||
  //       userAgent.includes("mac os")
  //     ) {
  //       return "Mac";
  //     } else if (userAgent.includes("linux")) {
  //       return "Linux";
  //     } else {
  //       return "Unknown";
  //     }
  //   };

  //   const platformDetected = detectPlatform();
  //   setPlatform(platformDetected);
  // }, []);

  const validateUsername = async (username) => {
    dispatch({ type: "AUTH_REQUEST" });
    try {
      const user = await getUserDetails(username);
      await generateAndSendOTP(user.phoneNumber);
      dispatch({ type: "AUTH_USERNAME_SUCCESS", payload: user });
      toast.success("OTP sent to your phone");
      setOtpRequested(true);
    } catch (error) {
      dispatch({ type: "AUTH_FAILURE", payload: error.message });
      setUsernameErrMsg(error.message);
      toast.error(
        error.response
          ? error.response.data &&
              error.response.data === "404 Not Found" ? "User not found" : "Problem occurred"
          : error.message + ". Problem occurred."
      );
    }
  };

  const authenticateOtp = async () => {
    dispatch({ type: "AUTH_REQUEST" });
    try {
      const { phoneNumber, email } = await getUserDetails(username);
      const userName = email.split("@")[0];
      const response = await validateOTP(phoneNumber, otp.join(""), userName);
      // await createUser(userName, platform, response);
      dispatch({ type: "AUTH_OTP_SUCCESS", payload: state.user });

           setOtp(["", "", "", "", "", ""]);
      login(response);
      toast.success("Logged in successfully");
      window.location.reload();
    } catch (error) {
      console.error("OTP :: ", error)
      dispatch({ type: "AUTH_FAILURE", payload: error.message });
      toast.error(error.response ? error.response.data : error.message);
    }
  };

  const isOtpValid = () => {
    return otp.every((value) => value.trim() !== "" && !isNaN(value));
  };

    const handleOtpChange = (index, value) => {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input field if value is entered
      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    };
  
  const resendOTP = async () => {
    setOtp(Array(6).fill(""));
    await generateAndSendOTP(state.user.phoneNumber);
    toast.success("OTP has been resend");
  };


    const handleRequestOtp = (e) => {
      e.preventDefault();
      if (!username.trim()) {
         toast.error("Please enter a username.");
         return setUsernameErrMsg("Please enter a username.");
       } else {
         validateUsername(username);
       }
    };

    const handleLogin = (e) => {
      e.preventDefault();
      // Handle login logic here
      if (!isOtpValid()) {
        toast.error("Empty/invalid OTP values");
        return;
      }
      authenticateOtp();
    };

  return (
    <Container>
      <LoginBox>
        <ImageSection />
        <FormSection>
          <HeaderContainer>
            <Logo src={logoImage} alt="Logo" />
            <AppName>Arifa</AppName>
          </HeaderContainer>
          <AppDescription>
            Arifa is an AI assistant designed to streamline your workflow and
            enhance productivity by providing real-time insights and support.
          </AppDescription>
          <Description>Please enter your credentials to log in.</Description>
          <form>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsernameValue(e.target.value.toLowerCase())}
              placeholder="Username"
              disabled={otpRequested}
            />
            {otpRequested && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {otp.map((digit, index) => (
                  <OTPInput
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    ref={(el) => (otpRefs.current[index] = el)}
                  />
                ))}
              </div>
            )}
            {otpRequested && <Link onClick={resendOTP}>Resend-OTP</Link>}
            <Button
              type="submit"
              onClick={otpRequested ? handleLogin : handleRequestOtp}
            >
              <div className="flex flex-row items-center justify-center">
                {state.loading && (
                  <div className="p-1">
                    <Spinner />
                  </div>
                )}
                <div>
                  {otpRequested
                    ? state.loading
                      ? "Processing..."
                      : "Login"
                    : state.loading
                    ? "Processing..."
                    : "Get OTP"}
                </div>
              </div>
            </Button>
          </form>
        </FormSection>
      </LoginBox>
    </Container>
  );
};

export default OtpForm;