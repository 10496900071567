import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { User, Phone, Mail } from "lucide-react";
import { useAppContext } from "../contexts/AppContext";
import { getAdCredentials, unlockUserAccount } from "../api/api";
import { toast } from "react-toastify";
import ResetPasswordModal from "./ResetPasswordModal";
import { getToken } from "../utils";

const Container = styled.div`
  display: flex;
  padding: 20px;
  background: #f5f5f5;
  min-height: 100vh;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
`;

const ProfileCard = styled(Card)`
  text-align: center;
  max-height: 35%;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    max-height: unset;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 20px;
  color: #fff;
  padding: 0px 5px;
  cursor: pointer;
`;

const Header = styled.h2`
  margin-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 1.5em;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  svg {
    margin-right: 10px;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: 20px;
  max-height: 35%;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-left: 0;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border-radius: 5px;
`;

const AccountStatusButton = ({ loading, status, infor, onUnlock }) => {
  const backgroundColor = loading ? "gray" : status === "Yes" ? "green" : "red";

  const label = loading
    ? "Loading..."
    : status === "Yes"
    ? "Active"
    : status === "No"
    ? "Disabled"
    : status === "Locked"
    ? "Unlock Account"
    : "Could not load data";

  return (
    <Button
      onClick={() => (status === "Locked" ? onUnlock(infor) : "")}
      style={{ background: backgroundColor, "border-radius": "5px" }}
    >
      {label}
    </Button>
  );
};

const PasswordResetButton = ({ loading, handlePassDialog, passDialog, appUser }) => {
  const backgroundColor = loading ? "gray" : "orange";

  return (
    <Button
      onClick={() => (!appUser ? null : handlePassDialog(!passDialog))}
      style={{ background: backgroundColor, "border-radius": "5px" }}
    >
      {loading ? "Loading..." : !appUser ? "Could not load data" : "Reset/Update"}
    </Button>
  );
};

const PasswordExpiryButton = ({ loading, daysUntilExpiry }) => {
  const backgroundColor = loading
    ? "gray"
    : daysUntilExpiry > 5
    ? "green"
    : daysUntilExpiry < 1
    ? "red"
    : "orange";

  const label = loading
    ? "Loading..."
    : daysUntilExpiry < 1
    ? "Password has expired"
    : isNaN(daysUntilExpiry)
    ? "Could not load data"
    : `${daysUntilExpiry} day${daysUntilExpiry > 1 ? "s" : ""}`;

  return (
    <Button
      style={{
        background: backgroundColor,
        cursor: "default",
        "border-radius": "5px",
      }}
    >
      {label}
    </Button>
  );
};

const AccountInfo = () => {
  const { appUser, loading } = useAppContext();
  const [userInfo, setUserInfo] = useState({});
  const [loadInfo, setLoading] = useState(true);
  const [passDialog, setPasswordDialog] = useState(false);

    useEffect(() => {
      if (!loading) {
        fetchUserInfo();
      }
    }, [appUser, loading]);

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const info = await getAdCredentials(appUser.email.split("@")[0], token);
      setUserInfo(info.body);
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setLoading(false);
    }
  };

  const unlockAccount = async (info) => {
    setLoading(true);
    try {
      const accessToken = getToken();
      const data = await unlockUserAccount(info.username, accessToken);
      if (data === 200) {
        setUserInfo({ ...info, accountActive: "Yes" });
        toast.success("Account unlocked successfully");
      } else {
        toast.success("Account could not be unlocked");
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      toast.success("Problem occured");
    } finally {
      setLoading(false);
    }
  };

  const daysUntilPasswordExpires = useMemo(() => {
    if (!userInfo.passwordExpires) return NaN;

    const expirationDate = new Date(userInfo.passwordExpires);
    const currentDate = new Date();

    const timeDifference = expirationDate - currentDate;
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }, [userInfo.passwordExpires]);

  return (
    <Container>
      <ProfileCard>
        <Header>Profile</Header>
        <InfoItem>
          <User />{" "}
          Name: {loading ? "Loading..." : !appUser ? "Could not load data" : appUser.displayName}
        </InfoItem>
        <InfoItem>
          <Phone />{" "}
          Phone: {loading ? "Loading..." : !appUser ? "Could not load data" : appUser.phoneNumber}
        </InfoItem>
        <InfoItem>
          <Mail />{" "}
          Email: {loading ? "Loading..." : !appUser ? "Could not load data" : appUser.email}
        </InfoItem>
      </ProfileCard>

      <FlexColumn>
        <Card>
          <Header>Account Information</Header>
          <List>
            <ListItem>
              <span>Account Status</span>
              <AccountStatusButton
                loading={loadInfo}
                status={userInfo.accountActive}
                infor={userInfo}
                onUnlock={unlockAccount}
              />
            </ListItem>
            <ListItem>
              <span>Password Expires In</span>
              <PasswordExpiryButton
                loading={loadInfo}
                daysUntilExpiry={daysUntilPasswordExpires}
              />
            </ListItem>
            <ListItem>
              <span>Reset AD password</span>
              <PasswordResetButton
                loading={loadInfo}
                handlePassDialog={setPasswordDialog}
                passDialog={passDialog}
                appUser={appUser}
              />
              {passDialog && (
                <ResetPasswordModal
                  passDialog={passDialog}
                  handlePassDialog={setPasswordDialog}
                />
              )}
            </ListItem>
          </List>
        </Card>
      </FlexColumn>
    </Container>
  );
};

export default AccountInfo;