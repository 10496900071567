import { InvalidTokenError, jwtDecode } from "jwt-decode";

// Utility function to decode the JWT without verifying its signature
export const decodeToken = (token) => {
  try {
    return jwtDecode(token); // Decodes the token but does not verify
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

export const getUsernameFromToken = (token) => {
    if (!token) return null;
  const payload = decodeToken(token);
    return payload?.sub || null;
  };

// Utility function to get the JWT from localStorage
export const getToken = () => {
  return localStorage.getItem("authToken"); // Assuming you store the token here
};

// Utility function to set the JWT in localStorage
export const setToken = (token) => {
  localStorage.setItem("authToken", token);
};

export const removeToken = () => {
  localStorage.removeItem("authToken");
};

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Invalid token", error);
    return false;
  }
};

export function isValidInput(input) {
  if (input.length < 14) {
    return false;
  }

  let hasAlphanumeric = false;
  let hasSymbol = false;

  for (let i = 0; i < input.length; i++) {
    const char = input[i];

    if (
      (char >= "a" && char <= "z") ||
      (char >= "A" && char <= "Z") ||
      (char >= "0" && char <= "9")
    ) {
      hasAlphanumeric = true;
    }

    if (
      !(
        (char >= "a" && char <= "z") ||
        (char >= "A" && char <= "Z") ||
        (char >= "0" && char <= "9")
      ) &&
      char !== " "
    ) {
      hasSymbol = true;
    }

    if (hasAlphanumeric && hasSymbol) {
      return true;
    }
  }

  return hasAlphanumeric && hasSymbol;
}