import { useState, useEffect } from "react";
import "./products.scss";
import DataTable from "../../components/dataTable/DataTable";
import Add from "../../components/add/CategoryAdd";
import { GridColDef } from "@mui/x-data-grid";
import { products } from "../../../data";
import { getAllFeedback } from "../../../api/api";
import { getToken } from "../../../utils";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "img",
    headerName: "Image",
    width: 100,
    renderCell: (params) => {
      return <img src={params.row.img || "/noavatar.png"} alt="" />;
    },
  },
  {
    field: "text",
    type: "string",
    headerName: "Text",
    width: 250,
  },
  // {
  //   field: "color",
  //   type: "string",
  //   headerName: "Color",
  //   width: 150,
  // },
  // {
  //   field: "price",
  //   type: "string",
  //   headerName: "Price",
  //   width: 200,
  // },
  // {
  //   field: "producer",
  //   headerName: "Producer",
  //   type: "string",
  //   width: 200,
  // },
  // {
  //   field: "createdAt",
  //   headerName: "Created At",
  //   width: 200,
  //   type: "string",
  // },
  // {
  //   field: "inStock",
  //   headerName: "In Stock",
  //   width: 150,
  //   type: "boolean",
  // },
];

const Products = () => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState([]);

  const fetchFeedback = async () => {
    const accessToken = getToken();
    const response = await getAllFeedback(accessToken);
    setFeedback(response);
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  return (
    <div className="products">
      <div className="info">
        <h1>Feedback</h1>
        {/* <button onClick={() => setOpen(true)}>Add New Products</button> */}
      </div>
      <DataTable slug="feedbacks" columns={columns} rows={feedback} />
      {/* TEST THE API */}

      {/* {isLoading ? (
        "Loading..."
      ) : (
        <DataTable slug="products" columns={columns} rows={data} />
      )} */}
      {open && <Add slug="feedback" columns={columns} setOpen={setOpen} />}
    </div>
  );
};

export default Products;
