import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #f7f7f7;
`;

const LinkItem = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25%;
  max-width: 400px;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const LinkTitle = styled.h3`
  margin: 0;
  font-size: 1.5em;
  color: #007bff;
`;

const LinkDescription = styled.p`
  margin: 10px 0 0;
  font-size: 1em;
  color: #555;
`;

const ConnectionLinksComponent = () => {
  const links = [
    {
      title: "Thika",
      url: "https://svdt5hcon03.safaricom.net/admin",
      description: "Access Thika resources",
    },
    {
      title: "Kiboswa",
      url: "https://svdt4hcon01.safaricom.net/admin",
      description: "Access Kiboswa resources",
    },
    {
      title: "Athi River",
      url: "https://svdt8hcon2.safaricom.net/admin",
      description: "Access Athi River resources",
    },
    // {
    //   title: "MFA Portal",
    //   url: "https://remoteaccess.safaricom.co.ke/multifactorauth/login.aspx?",
    //   description: "Multi-Factor Authentication User Portal",
    // },
  ];

  return (
    <PageContainer>
      {links.map((link, index) => (
        <LinkItem
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkTitle>{link.title}</LinkTitle>
          <LinkDescription>{link.description}</LinkDescription>
        </LinkItem>
      ))}
    </PageContainer>
  );
};

export default ConnectionLinksComponent;