import DataTable from "../../components/dataTable/DataTable";
import "./faq.scss";
import { useEffect, useState } from "react";
import Add from "../../components/add/CategoryAdd";
import { getCategories, getFaqs } from "../../../api/api";
import { getToken } from "../../../utils";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "topic",
    type: "string",
    headerName: "Title",
    width: 150,
  },
  {
    field: "attachment",
    type: "string",
    headerName: "Category",
    width: 200,
  },
  {
    field: "answer",
    type: "string",
    headerName: "Description",
    width: 200,
  },
  {
    field: "owner",
    type: "string",
    headerName: "Owner",
    width: 200,
  },
];

const Faq = () => {
  const [open, setOpen] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const fetchFaqs = async () => {
    const accessToken = getToken();
    const response = await getFaqs(accessToken);
    setFaqs(
      response.map((faq, index) => ({
        ...faq,
        id: index + 1,
      }))
    );
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <div className="users">
      <div className="info">
        <h1>Categories</h1>
      </div>
      <DataTable slug="users" columns={[...columns]} rows={faqs} />
      {/* {open && (
        <Add
          slug="category"
          columns={columns}
          categories={categories}
          setCategories={setCategories}
          setOpen={setOpen}
        />
      )} */}
    </div>
  );
};

export default Faq;
