import BarChartBox from "../../components/barChartBox/BarChartBox";
import BigChartBox from "../../components/bigChartBox/BigChartBox";
import ChartBox from "../../components/chartBox/ChartBox";
import PieChartBox from "../../components/pieCartBox/PieChartBox";
import TopBox from "../../components/topBox/TopBox";
import {
  barChartBoxRevenue,
  barChartBoxVisit,
  chartBoxConversion,
  chartBoxProduct,
  chartBoxRevenue,
  chartBoxUser,
} from "../../../data";
import "./home.scss";
import { useEffect, useState } from "react";
import { getToken } from "../../../utils";
import { getUserAnalyticsSummary } from "../../../api/api";

const Home = () => {
  const [userAnalyticsSummary, setUserAnalyticsSummary] = useState(null);

  const getSummaary = async () => {
    const accessToken = getToken();
    const res = await getUserAnalyticsSummary(accessToken);
    setUserAnalyticsSummary(res);
  }

  useEffect(() => {
    getSummaary();
  }, []);

  const updatedChartBoxUser = {
    ...chartBoxUser,
    number:
      userAnalyticsSummary == null ? 0 : userAnalyticsSummary.totalUsers,
    percentage:
      userAnalyticsSummary == null
        ? 0.0
        : userAnalyticsSummary.userPercentageDifference.toFixed(2),
  };
  const updatedChartBoxConversion = {
    ...chartBoxConversion,
    number: userAnalyticsSummary == null ? 0 : userAnalyticsSummary.totalVisits,
    percentage:
      userAnalyticsSummary == null ? 0.0 : userAnalyticsSummary.visitPercentageDifference.toFixed(2),
  };

  return (
    <div className="home">
      <div className="box box2">
        <ChartBox {...updatedChartBoxUser} />
      </div>
      <div className="box box3">
        <ChartBox {...chartBoxProduct} />
      </div>
      {/* <div className="box box4">
        <PieChartBox />
      </div> */}
      <div className="box box5">
        <ChartBox {...updatedChartBoxConversion} />
      </div>
      {/* <div className="box box6">
        <ChartBox {...chartBoxRevenue} />
      </div> */}
      {/* <div className="box box7">
        <BigChartBox />
      </div> */}
      {/* <div className="box box8">
        <BarChartBox {...barChartBoxVisit} />
      </div>
      <div className="box box9">
        <BarChartBox {...barChartBoxRevenue} />
      </div> */}
      <div className="box box1">
        <TopBox />
      </div>
    </div>
  );
};

export default Home;
