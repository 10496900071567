import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getFaqs, getModel } from "../../api/api";
import Greeting from "../Greeting";
import {
  SidebarContextProvider,
  useSideBarContext,
} from "../../contexts/SideBarContext";
import Markdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import AppHeader from "../AppHeader";
import { ArrowBigRight, Mail } from "lucide-react";
import { getToken } from "../../utils";

const TAGS = [
  {
    name: "VPN unable to send authentication notification/call for connection",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/2203px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png",
  },
  {
    name: "Problem loading secure sites on Chrome",
    image: "/images/sccm-apps.png",
  },
  { name: "Can't receive any email attachments?", image: "" },
  { name: "VPN cannot connect", image: "/images/status.png" },
];

const generateRandomId = (length) => {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  return Array.from({ length }, () =>
    characters.charAt(Math.floor(Math.random() * characters.length))
  ).join("");
};

const TagButton = ({ tag, onClick }) => (
  <div
    onClick={() => onClick(tag.topic)}
    className="relative cursor-pointer flex flex-col justify-start items-center p-4 bg-white h-48 w-48 m-1 rounded-lg font-futura-light text-lg text-gray-800 transition duration-300 ease-in-out hover:bg-gray-200"
  >
    <div className="flex items-center justify-center mb-2">
      <span className="font-futura-light">{tag.topic}</span>
    </div>
    <div className="absolute bottom-2 right-2">
      {tag.image ? (
        <img
          src={tag.image}
          alt="icon"
          className="w-6 h-6 rounded-full bg-white p-1"
        />
      ) : (
        <Mail size={18} className="w-6 h-6 rounded-full bg-white p-1" />
      )}
    </div>
  </div>
);

const ChatMessage = ({ message, isLoading, messages }) => (
  <>
    <span className={`flex ${message.type === "user" ? "self-end" : ""}`}>
      {message.type === "bot" && (
        <span className="flex h-10 w-10">
          <img src="/images/chat.png" alt="VA" />
        </span>
      )}
    </span>
    <div
      className={`mb-2 p-4 rounded-t-lg font-futura-bold text-sm bold ${
        message.type === "user"
          ? "rounded-bl-lg text-white self-end"
          : "rounded-br-lg bg-gray-100 text-gray-600 self-start"
      }`}
      style={{
        maxWidth: message.type === "user" ? "50%" : "80%",
        minWidth: message.type !== "user" ? "40%" : "unset",
        backgroundColor: message.type === "user" ? "#52b44b" : "white",
      }}
    >
      {message.text.answer && message.text.options ? (
        <>
          <Markdown className="markdown" remarkPlugins={[remarkBreaks]}>
            {message.text.answer}
          </Markdown>
          <div className="flex flex-col my-2">
            <p className="mt-2">More related queries:</p>
            {message.text.options.map((option, idx) => (
              <div
                key={idx}
                className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer underline my-1"
              >
                <ArrowBigRight size={12} />
                <span>{option}</span>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Markdown className="markdown" remarkPlugins={[remarkBreaks]}>
          {message.text}
        </Markdown>
      )}
    </div>
    {isLoading &&
      messages.length - 1 ===
        messages.indexOf(message) && (
          <img
            className="h-24 w-48"
            src="/images/loader.gif"
            alt="Loading..."
          />
        )}
  </>
);

const Chatbot = () => {
  const [inputMessage, setInputMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { darkMode } = useSideBarContext();
  // const [isOn, setIsOn] = useState(true);
  const latestContentRef = useRef(null);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (latestContentRef.current) {
      latestContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages, isLoading, darkMode]);

  // const handleToggle = () => {
  //   setIsOn(!isOn);
  // };

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === "") return;

    setIsLoading(true);
    try {
      const accessToken = getToken();
      const faqResponse = await getModel(inputMessage, accessToken);
        // : await getExternalModel(inputMessage);

      const userMessage = {
        id: generateRandomId(10),
        type: "user",
        text: inputMessage,
      };

      const botMessage = {
        id: generateRandomId(10),
        type: "bot",
        text: faqResponse
      };

      setChatMessages((prevMessages) => [
        ...prevMessages,
        userMessage,
        botMessage,
      ]);
      setInputMessage("");
    } catch (error) {
      toast.error(
        error.message.includes("404")
          ? "Requested data not found"
          : error.message.includes("401")
          ? "Problem occurred while connecting to the internal model."
          : "Unable to load data. Try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuClick = (tag) => {
    setInputMessage(tag);
  };

  useEffect(() => {
    const accessToken = getToken();
    const fetchFaqs = async () => {
      const res = await getFaqs(accessToken);
      setTags(res);
    }
    fetchFaqs();
  },[])

  return (
    <SidebarContextProvider>
      <div
        className="flex flex-col h-screen shadow rounded overflow-hidden"
        style={{ width: "100%", background: "#f4f4f9" }}
      >
        <AppHeader title="Chat With Arifa Assistant" />
        <div className="p-3 flex flex-col h-full overflow-y-auto justify-between">
          <div className="overflow-y-auto flex flex-auto pr-6 pl-6 justify-center">
            <div className="flex flex-col w-[850px]">
              <div className="mb-2 p-2 mt-5 font-futura-bold text-lg bold">
                <Greeting />
              </div>
              <div className="flex flex-wrap p-3">
                {tags.map((tag) => (
                  <TagButton
                    key={tag.id}
                    tag={tag}
                    onClick={handleMenuClick}
                  />
                ))}
              </div>
              {chatMessages.map((message, index) => (
                <ChatMessage
                  key={index}
                  message={message}
                  isLoading={isLoading}
                  messages={chatMessages}
                />
              ))}
              <div ref={latestContentRef} />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <form
              onSubmit={handleSendMessage}
              className="mt-2 w-[70%] flex items-center justify-center"
            >
              <input
                data-testid="bot-msg"
                type="text"
                placeholder="Input your prompt"
                className="border-2 border-green-500 rounded-full px-4 py-4 w-full"
                value={inputMessage}
                onChange={handleInputChange}
              />
              <img
                type="submit"
                onClick={handleSendMessage}
                src="/images/send-g.png"
                className="h-16 w-16 cursor-pointer text-red-500"
                alt="SEND"
              />
            </form>
          </div>
        </div>
      </div>
    </SidebarContextProvider>
  );
};

export default Chatbot;