import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { createCategory, getCategories, postFaq } from "../api/api";
import styled from "styled-components";
import Markdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { useAppContext } from "../contexts/AppContext";
import { getToken } from "../utils";

const DialogContainer = styled.div`
  background: #fff;
  padding: 24px;
  width: 80%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: auto;
  margin-bottom: 10px;
`;

const DialogTitle = styled.h2`
  margin: 0 0 16px 0;
  font-size: 24px;
`;

const DialogContent = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const DialogActions = styled.div`
  display: flex;
`;

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const SelectField = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
`;

const TextAreaField = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#007BFF" : "#ccc")};
  color: ${(props) => (props.primary ? "#fff" : "#000")};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0056b3" : "#bbb")};
  }
`;

const ErrorText = styled.p`
  color: red;
  margin: 8px 0;
`;

const FaqUploadForm = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [customCategory, setCustomCategory] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { appUser, user } = useAppContext();

  useEffect(() => {
    let isMounted = true;
    const accessToken = getToken();

    const fetchCategories = async () => {
      setLoading(true);
      try {
        const res = await getCategories(accessToken);
        if (isMounted) setCategories(formattedCategories(res));
      } catch (error) {
        if (isMounted) console.error("Error fetching categories: ", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchCategories();

    // Cleanup to avoid memory leaks
    return () => {
      isMounted = false;
    };
  }, []);

  // Memoize the formatted categories function to prevent recreation on every render
  const formattedCategories = useCallback((data) => {
    return [
      { id: 1, value: "", label: "Select a category" },
      ...data.map((item, index) => ({
        id: index + 2,
        value: item.categoryName?.toLowerCase() || "",
        label: item.categoryName?.toUpperCase() || "Unknown",
      })),
      { id: data.length + 2, value: "other", label: "Other" },
    ];
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!title || !description) {
        setError("Please fill in all fields.");
        return;
      }

      const accessToken = getToken();

      const selectedCategory = category === "other" ? customCategory : category;

      if (category === "other" && appUser?.email) {
        try {
          const res = await createCategory(
            appUser.email.split("@")[0],
            customCategory,
            accessToken
          );
        } catch (error) {
          console.error("Error creating category :: ", error);
          toast.error("Failed to create category. Please try again.");
          return;
        }
      }

      // Post the FAQ and update state accordingly
      try {
        const adUsername = appUser.email.split("@")[0];
        await postFaq({
          title,
          category: selectedCategory,
          description,
          adUsername,
          accessToken,
        });

        // Update categories list with a new entry if "other" was selected
        setCategories((prevCategories) => [
          ...prevCategories.filter((cat) => cat.value !== "other"), // Remove the last "other"
          {
            id: prevCategories.length + 1,
            value: selectedCategory,
            label: selectedCategory.toUpperCase(),
          },
          { id: prevCategories.length + 2, value: "other", label: "Other" },
        ]);

        toast.success("FAQ uploaded successfully");
        setTitle("");
        setCategory("");
        setCustomCategory("");
        setDescription("");
        setError(null);
      } catch (err) {
        toast.error("Problem occurred. Please try again.");
      }
    },
    [title, category, customCategory, description, appUser]
  );

  const handleCategoryChange = useCallback((e) => {
    setCategory(e.target.value);
  }, []);

  return (
    <div className="overflow-scroll w-full">
      <DialogContainer>
        <DialogTitle>Upload FAQ</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {error && <ErrorText>{error}</ErrorText>}
            <InputField
              type="text"
              placeholder="Title: Unable to setup MFA"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <SelectField
              value={category}
              onChange={handleCategoryChange}
              required
            >
              {loading
                ? "Loading..."
                : categories.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
            </SelectField>
            {/* Show custom category input when "Other" is selected */}
            {category === "other" && (
              <InputField
                type="text"
                placeholder="Enter custom category"
                value={customCategory}
                onChange={(e) => setCustomCategory(e.target.value)}
                required
              />
            )}
            <TextAreaField
              placeholder="Description: Steps or description on how to resolve the FAQ"
              rows="4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button primary type="submit">
              Upload
            </Button>
          </DialogActions>
        </form>
      </DialogContainer>
      {description && (
      <DialogContainer>
        <Markdown className="markdown" remarkPlugins={[remarkBreaks]}>
          {description}
        </Markdown>
      </DialogContainer>)}
    </div>
  );
};

export default FaqUploadForm;