import { createContext, useReducer, useContext } from 'react';
import { getAllDevices } from '../api/api';
import deviceStatusReducer, { initialState } from '../reducers/deviceStatusReducer';
import { getToken } from '../utils';

export const DeviceStatusContext = createContext();

export const useDeviceStatusContext = () => useContext(DeviceStatusContext);

export const DeviceStatusContextProvider = ({children}) => {
    const [ state, dispatch] = useReducer(deviceStatusReducer, initialState);

    const fetchDeviceStatus = async (username) => {
        dispatch({ type: 'FETCH_DEVICE_STATUS_REQUEST' });
        try {
            const accessToken = getToken();
            const devices = await getAllDevices(username, accessToken);
          dispatch({ type: 'FETCH_DEVICE_STATUS_SUCCESS', payload: devices.body });
        } catch (error) {
            dispatch({ type: 'FETCH_DEVICE_STATUS_FAILURE', payload: error.message });
        }
    };

    const value = {
        state,
        fetchDeviceStatus
    }
    
    return (
    <DeviceStatusContext.Provider value={value}>
        {children}
    </DeviceStatusContext.Provider>
    );
}