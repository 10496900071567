import DataTable from "../../components/dataTable/DataTable";
import "./category.scss";
import { useEffect, useState } from "react";
import Add from "../../components/add/CategoryAdd";
import { getCategories } from "../../../api/api";
import { getToken } from "../../../utils";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "categoryName",
    type: "string",
    headerName: "Category Name",
    width: 150,
  },
  {
    field: "createdAt",
    type: "string",
    headerName: "Created At",
    width: 200,
  },
  {
    field: "createdBy",
    type: "string",
    headerName: "Created By",
    width: 200,
  },
];

const Category = () => {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    const accessToken = getToken();
    const response = await getCategories(accessToken);
    setCategories(
      response.map((category, index) => ({
        ...category,
        id: index + 1,
      }))
    );
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="users">
      <div className="info">
        <h1>Categories</h1>
        <button onClick={() => setOpen(true)}>Add New Category</button>
      </div>
      <DataTable slug="users" columns={[...columns]} rows={categories} />
      {open && (
        <Add
          slug="category"
          columns={columns}
          categories={categories}
          setCategories={setCategories}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default Category;
